
.page-container{
    display: grid;
    justify-content: center;
    align-items: center;
}


.array-container{
    
    align-items: center;
    display: block;
    width: 100%;
    height: 100%;
    margin-top: 200px;
}

.array-bar {
    
    width: 2px;
    display:  inline-block;
    margin: 0 1px;
    position: center;
}


.button-container{
    display: grid;
   
    grid-template-columns: repeat(5, auto-fit);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 200px;
    margin: auto;

    margin-bottom: 100px;
    
}
.button{
    background-color: #404040; 
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    position: center;
}
.button:hover{

    background-color: #404040; 
    border: none;
    color: #0ff;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    position: center;
}

