.general-container{
    background-color: white;
    
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.general-container > h1{
    margin-top: 50px;
    color:  #000;
    font-size: 5vw;
}
.general-container > h2{
    color:  #000;
    font-size: 3vw;
}
.general-container > p{
    margin-right: 20vw;
    margin-left: 20vw;
    color:  grey;
    font-size: 3vw;
    text-align: center;
    margin-bottom: 50px;
}

@media screen and (min-width: 600px) {
    .general-container{
        background-color: white;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .general-container > h1{
        margin-top: 50px;
        color:  #000;
        font-size: 35px;
    }
    .general-container > h2{
        color:  #000;
        font-size: 21px;
    }
    .general-container > p{
        
        color:  grey;
        font-size: 21px;
        text-align: center;
        margin-bottom: 50px;
    }
}